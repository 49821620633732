import React from "react";
import "./Shows.css"

import ShowEntry from "./ShowEntry/ShowEntry";


const UPCOMING_SHOW_DATA = [

    { date: '12-14-2024', venue: 'Los Trece', location: 'Early, TX', link: 'https://www.lostrece.com/'},
    { date: '02-28-2025', venue: '37 Main', location: 'Buford, GA', link: 'https://buford.37main.com/'},
    { date: '03-10-2025', venue: 'Rio Grande Valley Livestock Show and Rodeo', location: 'Mercedes, TX', link: 'https://www.rgvls.com/'},
    { date: '04-11-2025', venue: 'Newtown Theatre', location: 'Newtown, PA', link: 'https://www.thenewtowntheatre.com/'},
];

const PAST_SHOW_DATA = [
    { date: '09-14-2024', venue: 'Barnhill Vineyard', location: 'Anna, TX', link: 'https://www.eventbrite.com/e/guns-n-roses-covered-by-guns-4-roses-anna-tx-tickets-929815732887?aff=ebdsoporgprofile'},
    { date: '09-06-2024', venue: 'Rockin on the River', location: 'Lorain, OH', link: 'https://www.rockinontheriver.com/'},
    { date: '08-30-2024', venue: 'Chop Shop Live', location: 'Roanoke, TX', link: 'https://www.chopshoplive.com/'}, 
    { date: '08-23-2024', venue: 'Cowboy Heritage Days', location: 'Pleasanton, TX', link: 'https://www.pleasantontx.gov/departments/civic_center/cowboy_heritage_days.php'}, 
    { date: '08-17-2024', venue: 'Red Rock & Blue benefiting Louisiana Military Charities at The Texas Club', location: 'Baton Rouge, LA', link: 'https://www.thetexasclub.com/'}, 
    { date: '07-19-2024', venue: 'Legends Pub House & Venue', location: 'Chickasha, OK', link: 'https://legendspubhouseandvenue.com/'},
    { date: '07-05-2024', venue: 'Barbay at Marina del Rey', location: 'Kingston, OK', link: 'http://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    { date: '06-22-2024', venue: 'Scooter Brown & Charlie Diggs BA Lake Fest', location: 'Montgomery, TX', link: 'https://www.outhousetickets.com/Event/23733-Scooter_Brown__Charlie_Diggs_Bad_Ass_Lake_Fest/'}, 
    { date: '06-21-2024', venue: 'Hot Sounds of Summer at Mayor Vera Calvin Plaza', location: 'Burleson, TX', link: 'https://www.burlesontx.com/1779/Hot-Sounds-of-Summer'}, 
    { date: '05-18-2024', venue: 'Biker Fest', location: 'Piedras Negras, Mexico', link: 'https://www.facebook.com/bikerfestpnegras'}, 
    { date: '03-16-2024', venue: 'ShamRock at The Lodge at Trinity Falls', location: 'McKinney, TX', link: ''},
    { date: '03-02-2024', venue: '37 Main', location: 'Buford, GA', link: 'https://buford.37main.com/'},
    { date: '02-24-2024', venue: 'Strawberry Festival', location: 'The Villages, FL', link: 'https://www.thevillagesentertainment.com/special-events/strawberry-festival-2024/'},
    {date: '09-23-2023', venue: 'Ameristar Casino', location: 'Vicksburg, MS', link: 'https://www.ticketmaster.com/guns-4-roses-vicksburg-mississippi-09-23-2023/event/1B005E95328AB182'}, 
    {date: '09-22-2023', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '08-12-2023', venue: "Rusty Spur Steakhouse & Saloon, Summer's Last Blast Concert", location: 'Vernon, TX', link: 'https://www.facebook.com/rustyspurvernontexas/'}, 
    {date: '08-11-2023', venue: 'Barbay at Marina del Rey', location: 'Kingston, OK', link: 'http://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    {date: '07-29-2023', venue: 'Tanana Valley State Fairgrounds', location: 'Fairbanks, AK', link: 'https://aktickets.com/events/guns-4-roses-walk-this-way-7-29-2023'}, 
    {date: '07-22-2023', venue: '37 Main Cumming Music Festival', location: 'Cumming, GA', link: 'https://buford.37main.com/2023-cumming-music-festival/'}, 
    {date: '07-14-2023', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '05-06-2023', venue: '4 Way', location: 'Lakehills, TX', link: 'https://www.4waybarandgrill.com/'}, 
    {date: '04-29-2023', venue: 'Starlight Music Series, Wolf Pen Amphitheater', location: 'College Station, TX', link: ''}, 
    {date: '04-01-2023', venue: "Willie's Joint", location: 'Buda, TX', link: 'https://williesjoint.com/new-events'}, 
    {date: '12-17-2022', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '11-05-2022', venue: '37 Main Avondale Estates', location: 'Avondale Estates, GA', link: 'https://avondale.37main.com/shows/'}, 
    {date: '11-04-2022', venue: '37 Main Buford', location: 'Buford, GA', link: 'https://buford.37main.com/shows/'}, 
    {date: '09-10-2022', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '09-03-2022', venue: 'Barbay at Marina del Rey', location: 'Kingston, OK', link: 'http://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    {date: '09-02-2022', venue: 'Chop Shop Live', location: 'Roanoke, TX', link: 'https://www.chopshoplive.com/events-at-roanoke'}, 
    {date: '08-27-2022', venue: 'Tribute to Summer, Early City Park', location: 'Early, TX', link: ''}, 
    {date: '06-17-2022', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '05-14-2022', venue: 'Palm Fest', location: 'Port Aransas, TX', link: 'https://southerncoastopen.com/pages/palmfest-lineup'}, 
    {date: '05-13-2022', venue: 'Chop Shop Live', location: 'Roanoke, TX', link: 'https://www.chopshoplive.com/events-at-roanoke'}, 
    {date: '04-09-2022', venue: 'The Ritz Theater with Dressed to Kill', location: 'Shawnee, OK', link: 'https://ritzshawnee.com/'}, 
    {date: '03-19-2022', venue: 'Bottleneck Blues Bar, Ameristar Casino', location: 'Vicksburg, MS', link: 'https://www.ameristarvicksburg.com/entertainment'}, 
    {date: '02-26-2022', venue: 'Sombrero Festival', location: 'Brownsville, TX', link: 'https://www.sombrerofestival.com/musiclineup'}, 
    {date: '02-25-2022', venue: 'San Antonio Stock Show & Rodeo, Bud Light Live! Rodeo Watch Party', location: 'San Antonio, TX', link: 'https://www.sarodeo.com/p/fair'}, 
    {date: '01-15-2022', venue: 'Kingfisher County Fairgrounds', location: 'Kingfisher, OK', link: 'https://www.eventbrite.com/e/guns-4-roseswalk-this-way-tickets-215883502367'}, 
    {date: '11-20-2021', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://www.eventbrite.com/o/lava-cantina-the-colony-18690227135'}, 
    {date: '11-13-2021', venue: '37 Main Buford', location: 'Buford, GA', link: 'https://buford.37main.com/shows/'}, 
    {date: '11-12-2021', venue: '37 Main Avondale', location: 'Avondale, GA', link: 'https://avondale.37main.com/shows/'}, 
    {date: '10-16-2021', venue: 'Chopshop Live', location: 'Roanoke, TX', link: 'http://www.chopshoplive.com'}, 
    {date: '09-25-2021', venue: 'Rock the Farm Festival', location: 'Seaside Heights, NJ', link: 'https://www.rockthefarmnj.com'}, 
    {date: '09-18-2021', venue: 'The Garden at Pacific Trails', location: 'Elsa, TX', link: 'http://www.topboxtickets.com/guns-4-roses-tribute-band-saturday-september-18-2021-at-the-garden-elsa-tx/'}, 
    {date: '08-07-2021', venue: 'Douglas County Fair with Appetite for Deception', location: 'Roseburg, OR', link: ''}, 
    {date: '07-17-2021', venue: 'Barbay, Marina del Rey', location: 'Kingston, OK', link: 'https://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    {date: '07-16-2021', venue: 'Private Party', location: 'Oklahoma City, OK', link: ''}, 
    {date: '06-12-2021', venue: 'Tanana Valley Fairgrounds', location: 'Fairbanks, AK', link: 'https://www.tixr.com/groups/ericksonevents/events/guns-4-roses-walk-this-way-tribute-concert-21856'}, 
    {date: '06-11-2021', venue: 'Matanuska Brewing Company', location: 'Eagle River, AK', link: 'https://www.tixr.com/groups/ericksonevents/events/guns-4-roses-walk-this-way-tribute-concerts-21489'}, 
    {date: '05-21-2021', venue: 'Lava Cantina', location: 'The Colony, TX', link: ''}, 
    {date: '05-01-2021', venue: 'Private Party', location: 'Norman, OK', link: ''}, 
    {date: '10-24-2020', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantinathecolony.ticketfly.com/'}, 
    {date: '10-03-2020', venue: '40 West', location: 'Yukon, OK', link: 'https://l.facebook.com/l.php?u=https%3A%2F%2F40west.ticketleap.com%2Fg4r%3Ffbclid%3DIwAR1Wfy27uq3uoL7H0hbTA_MikVlVnEF-cLr8ZzoEkj1kXZ8K7ZE12AvkKMk&h=AT0cjpVA6_-MCYYdU6mZoKyZz95-DYoyqK6LZwvBd7Y8-APM_PSaJ0IhZ95III0X5UH3oCCRhkh3el51GUC6OMIetz7WSczdHDxYfOTHJ7NtXJ8kg1R-04oqBIzP9yU2YOZtpVQ'}, 
    {date: '09-25-2020', venue: 'Rock the Lot, Glass Cactus at Gaylord Texan Resort', location: 'Grapevine, TX', link: 'https://www.eventbrite.com/e/glass-cactus-present-guns-4-roses-live-with-metal-shop-tickets-118093519897'}, 
    {date: '08-01-2020', venue: 'Muenster MVFD Mudbug Bash', location: 'Muenster, TX', link: 'https://www.facebook.com/events/273339407119735/'}, 
    {date: '07-31-2020', venue: 'Gas Monkey', location: 'Dallas, TX', link: 'https://www.gasmonkeybarngrill.com/e/guns-roses-108868410346/'}, 
    {date: '06-27-2020', venue: 'Barbay at Marina del Rey', location: 'Kingston, OK', link: 'http://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    {date: '02-08-2020', venue: 'Choctaw Casino', location: 'Pocola, OK', link: ''}, 
    {date: '10-12-2019', venue: 'University DraftHouse BeerFest', location: 'Edinburg, TX', link: 'https://www.universitydrafthousebeerfest.com/'}, 
    {date: '10-11-2019', venue: 'Happy Cow', location: 'New Braunfels, TX', link: 'https://www.happycowtexas.com'}, 
    {date: '08-09-2019', venue: 'Lava Cantina', location: 'The Colony, TX', link: 'https://lavacantina.com/live-entertainment/'}, 
    {date: '07-20-2019', venue: 'Private Party', location: 'Houston, TX', link: ''}, 
    {date: '07-06-2019', venue: 'Barbay at Marina del Rey', location: 'Kingston, OK', link: 'http://marinadelreyok.com/dining-and-entertainment/barbay-on-lake-texoma/'}, 
    {date: '07-05-2019', venue: 'Gas Monkey', location: 'Dallas, TX', link: 'https://www.gasmonkeybarngrill.com'}, 
    {date: '07-04-2019', venue: 'Sundown Salute', location: 'Junction City, KS', link: 'https://www.sundownsalutejc.org/'}, 
    {date: '06-29-2019', venue: 'Dyess Air Force Base (Private Event)', location: 'Abilene, TX', link: ''}, 
    {date: '06-28-2019', venue: "Fat Daddy's with Poison Cherry", location: 'Mansfield, TX', link: 'https://www.fatdaddyslive.com'}, 
    {date: '05-03-2019', venue: "River Rock Fest, Joe Jimmy's at Neals Lodges", location: 'Concan, TX', link: 'https://www.nealslodges.com/river-rock-fest/'}, 
    {date: '03-31-2019', venue: 'Big Texas Fun Festival', location: 'Selma, TX', link: 'https://bigtxfun.com/'}, 
    {date: '03-16-2019', venue: '40 West', location: 'Yukon (Oklahoma City), OK', link: 'https://www.facebook.com/40westbarandgrill/'}, 
    {date: '03-15-2019', venue: 'Fort Worth Harley Davidson, Grand Opening Rally', location: 'Fort Worth, TX', link: 'https://www.fwharley.com/check-the-events-calendar--calendar'}, 
    {date: '03-02-2019', venue: 'Sombrero Festival', location: 'Brownsville, TX', link: 'http://www.sombrerofestival.com/2016-2/'}, 
    {date: '02-23-2019', venue: 'River Run ATV Park', location: 'Jacksonville, TX', link: 'https://www.riverrunpark.com/'}, 
    {date: '02-13-2019', venue: 'Private Event', location: 'Houston, TX', link: ''}, 
    {date: '11-25-2018', venue: 'Grey Cup Festival, Clarke Park Tailgate Party with Platinum Blonde', location: 'Edmonton, Alberta, Canada', link: 'http://www.greycupfestival.ca/#events'}, 
    {date: '11-17-2018', venue: 'Dog House', location: 'Brownsville, TX', link: ''}, 
    {date: '11-16-2018', venue: 'Leaky Barrel', location: 'San Antonio, TX', link: ''}, 
    {date: '10-27-2018', venue: "Fat Daddy's Halloween Party with Saints & Sinners (Whitesnake)", location: 'Fort Worth, TX', link: 'https://fatdaddyslive.com/fortworth/live-events/'}, 
    {date: '10-26-2018', venue: 'Cooterfest', location: 'Inverness, FL', link: 'https://www.cooterfestival.com'}, 
    {date: '10-13-2018', venue: 'Blake Street Bar', location: 'Columbus, TX', link: ''}, 
    {date: '10-12-2018', venue: 'Hanovers 2.0', location: 'Austin, TX', link: 'http://www.hanovers2.com'}, 
    {date: '09-29-2018', venue: 'Rock the Farm Festival, Fauxchella', location: 'Seaside Heights, NJ', link: 'http://www.faux-chella.com'}, 
    {date: '09-08-2018', venue: 'Gas Monkey', location: 'Dallas, TX', link: 'http://www.gasmonkeybarngrill.com/concerts-events/'}, 
    {date: '09-07-2018', venue: 'Club Fuego', location: 'Abilene, TX', link: ''}, 
    {date: '08-04-2018', venue: 'Private Party', location: 'Azle, TX', link: ''}, 
    {date: '08-03-2018', venue: 'Iron Horse Pub', location: 'Wichita Falls, TX', link: 'http://www.theironhorsepub.com'}, 
    {date: '07-21-2018', venue: 'Lincoln Loon Festival', location: 'Lincoln, ME', link: ''}, 
    {date: '05-26-2018', venue: 'Memorial Day Mudbug Ride, River Run ATV Park, with Back in Black', location: 'Jacksonville, TX', link: ''}, 
    {date: '05-25-2018', venue: 'Memorial Weekend Bash', location: 'Victoria, TX', link: ''}, 
    {date: '05-24-2018', venue: 'Forney Music in the Park Concert Series', location: 'Forney, TX', link: ''}, 
    {date: '04-27-2018', venue: 'Da Pub', location: 'Pasadena, TX', link: ''}, 
    {date: '02-17-2018', venue: 'Gas Monkey, with Walk This way', location: 'Dallas, TX', link: 'http://www.gmbg.com'}, 
    {date: '02-16-2018', venue: 'Iron Horse Pub, with Walk This Way', location: 'Wichita Falls, TX', link: ''}, 
    {date: '12-16-2017', venue: 'Firehouse BNG', location: 'Abilene, TX', link: ''}, 
    {date: '12-15-2017', venue: 'Fat Daddys', location: 'Mansfield, TX', link: ''}, 
    {date: '12-02-2017', venue: 'Mikko Live at Coushatta Casino Resort', location: 'Coushatta, LA', link: 'http://www.coushattacasinoresort.com/entertainment/mikko/'}, 
    {date: '10-20-2017', venue: 'Leaky Barrel', location: 'San Antonio, TX', link: ''}, 
    {date: '10-19-2017', venue: 'Rocktober Street Dance benefitting St. Judes Childrens Hospital', location: 'Victoria, TX', link: ''}, 
    {date: '08-30-2017', venue: "Knoxville's Tavern", location: 'Edmonton, Alberta, Canada', link: 'http://knoxedmonton.com/events/events_detail/guns_4_roses'}, 
    {date: '07-29-2017', venue: 'Iron Horse Pub', location: 'Wichita Falls, TX', link: 'http://www.theironhorsepub.com'}, 
    {date: '07-28-2017', venue: 'Gas Monkey', location: 'Dallas, TX', link: ''}, 
    {date: '07-17-2017', venue: "Eskimo Joe's 42nd Anniversary Bash,  benefiting Folds of Honor", location: 'Stillwater, OK', link: 'http://eskimojoes.com'}, 
    {date: '07-01-2017', venue: 'Tom Sawyer Days', location: 'Hannibal, MO', link: 'http://www.hannibaljaycees.org/ntsd-2016.html'}, 
    {date: '05-26-2017', venue: 'Bronson Rock', location: 'Keller, TX', link: 'http://bronsonrocktx.com'}, 
    {date: '05-06-2017', venue: 'Private Party', location: 'Wall, TX', link: ''}, 
    {date: '05-05-2017', venue: "Shenanigan's", location: 'San Angelo, TX', link: ''}, 
    {date: '04-23-2017', venue: 'Throwback Fiesta, Maverick Park', location: 'San Antonio, TX', link: 'http://saparksfoundation.org/events/throwback-fiesta-test/'}, 
    {date: '04-22-2017', venue: 'Scout Bar', location: 'Houston, TX', link: 'http://www.scoutbar.com'}, 
    {date: '04-01-2017', venue: 'Texas Food Truck Showdown', location: 'Waco, TX', link: 'http://www.thetexasfoodtruckshowdown.com'}, 
    {date: '03-31-2017', venue: 'Leaky Barrel', location: 'San Antonio, TX', link: 'https://www.facebook.com/leakybarrelsa/'}, 
    {date: '02-11-2017', venue: 'Choctaw Casino', location: 'Broken Bow, OK', link: 'https://www.choctawcasinos.com/choctaw-broken-bow/entertainment/upcoming-shows/'}, 
    {date: '02-10-2017', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '01-14-2017', venue: 'The Slowdown with Walk This Way, A Tribute to Aerosmith', location: 'Omaha, NE', link: 'http://theslowdown.com/shows.cfm'}, 
    {date: '12-11-2016', venue: 'Rock & Brews San Manuel Casino', location: 'Highland, CA', link: 'http://www.rockandbrews.com/location/san-manuel-indian-bingo-casino/'}, 
    {date: '11-12-2016', venue: 'Burleson Wine Crawl', location: 'Burleson, TX', link: 'http://www.burlesonwinecrawl.com'}, 
    {date: '10-29-2016', venue: 'Halloween Howler featuring Bush, Theory of a Deadman, and Whale and the Wolf, Shaw Conference Centre', location: 'Edmonton, Canada', link: ''}, 
    {date: '10-15-2016', venue: 'Jailhouse Rock Fest, Coliseo UniNorte', location: 'Barranquilla, Colombia', link: ''}, 
    {date: '10-13-2016', venue: 'Kukaramakara', location: 'Bogotá, Colombia', link: ''}, 
    {date: '10-08-2016', venue: 'Gas Monkey Live', location: 'Dallas, TX', link: 'http://www.gasmonkeybarngrill.com'}, 
    {date: '09-10-2016', venue: 'Pepsi Amp', location: 'Flagstaff, AZ', link: 'http://pepsiamp.com/events/'}, 
    {date: '09-03-2016', venue: 'Leaky Barrel', location: 'San Antonio, TX', link: ''}, 
    {date: '09-02-2016', venue: 'Palominio Fest', location: 'Uvalde, TX', link: 'http://palominofest.com'}, 
    {date: '08-13-2016', venue: 'Shenanigans', location: 'San Angelo, TX', link: ''}, 
    {date: '07-30-2016', venue: 'Lac Vieux Desert Casino', location: 'Watersmeet, MI', link: 'http://www.lvdcasino.com/index.cfm'}, 
    {date: '06-18-2016', venue: 'Rock of Ages, Tributo al Rock con Back in Black', location: 'San Pedro Sula, Honduras', link: 'https://www.facebook.com/Rock-of-Ages-Tour-1712236105699654/?fref=ts'}, 
    {date: '06-17-2016', venue: 'Irving Free Concert Series, Whistlestop Plaza', location: 'Irving, TX', link: 'http://cityofirving.org/1267/Irving-Concert-Series'}, 
    {date: '06-11-2016', venue: 'ROT Rally, Travis County Expo Center', location: 'Austin, TX', link: 'http://www.rotrally.com'}, 
    {date: '06-10-2016', venue: "Papa Woody's Roadhouse", location: 'San Antonio, TX', link: ''}, 
    {date: '05-21-2016', venue: 'Eaglefest 2016, The Waterfront Lodge & Marina', location: 'Onalaska, TX', link: 'http://www.923theeagle.com/index.php'}, 
    {date: '04-16-2016', venue: 'Paris Harley-Davidson Chili Cookoff, Paris Harley-Davidson', location: 'Paris, TX', link: 'http://www.parisharley.com'}, 
    {date: '03-05-2016', venue: 'Borderfest, State Farm Arena', location: 'Hidalgo, TX', link: ''}, 
    {date: '02-27-2016', venue: 'Shenanigans', location: 'San Angelo, TX', link: ''}, 
    {date: '02-26-2016', venue: 'Gas Monkey', location: 'Dallas, TX', link: ''}, 
    {date: '10-17-2015', venue: 'Bike Fest', location: 'Santo Domingo, Dominican Republic', link: ''}, 
    {date: '10-16-2015', venue: 'Hard Rock Cafe, Punta Cana', location: 'Dominican Republic"', link: ''}, 
    {date: '10-15-2015', venue: 'Hard Rock Cafe, Santo Domingo', location: 'Dominican Republic"', link: ''}, 
    {date: '09-04-2015', venue: 'Gas Monkey', location: 'Dallas, TX', link: ''}, 
    {date: '08-29-2015', venue: 'Concrete Street Amp, Classic Rock 104.5 Rock & Ride', location: 'Corpus Christi, TX', link: ''}, 
    {date: '08-28-2015', venue: 'Fitzgeralds', location: 'San Antonio, TX', link: ''}, 
    {date: '07-03-2015', venue: 'Choctaw Casino Block Party', location: 'Broken Bow, OK', link: ''}, 
    {date: '05-30-2015', venue: 'Piedras Negras Bike Fest', location: 'Piedras Negras, Mexico', link: ''}, 
    {date: '05-01-2015', venue: 'Private Party, Deep Ellum', location: 'Dallas, TX', link: ''}, 
    {date: '02-21-2015', venue: 'Gas Monkey Live!', location: 'Dallas, TX', link: ''}, 
    {date: '02-14-2015', venue: 'Tributo Dioses del Rock, Teatro Metropol', location: 'Bogotá, Colombia', link: ''}, 
    {date: '01-24-2015', venue: 'River Cree Resort & Casino, Enoch', location: 'Edmonton, Alberta, Canada', link: ''}, 
    {date: '11-15-2014', venue: 'Landers Trail Lizards Jeep Jam, Muddy Bottoms', location: 'Sarepta, LA', link: ''}, 
    {date: '08-23-2014', venue: '105.3 The Fan’s Sausage Fest, Main Street Garden Park', location: 'Dallas, TX', link: ''}, 
    {date: '08-03-2014', venue: 'BOBStock, Zoo Amphitheater', location: 'Oklahoma City, OK', link: ''}, 
    {date: '07-19-2014', venue: 'Blackgold Rally, La Hacienda Event Center', location: 'Midland, TX', link: ''}, 
    {date: '07-15-2014', venue: 'Tribute Tuesdays, Kemah Boardwalk', location: 'Kemah, TX', link: ''}, 
    {date: '07-11-2014', venue: 'Gas Monkey', location: 'Dallas, TX', link: ''}, 
    {date: '06-28-2014', venue: 'Miller Lite Experience', location: 'San Pedro Sula, Honduras', link: ''}, 
    {date: '05-31-2014', venue: 'Hard Rock Resort', location: 'Cancun, Mexico', link: ''}, 
    {date: '05-09-2014', venue: "Billy Bob's", location: 'Fort Worth, TX', link: ''}, 
    {date: '04-19-2014', venue: 'Paris Harley-Davidson Chili Cook Off', location: 'Paris, TX', link: ''}, 
    {date: '04-12-2014', venue: 'Sports City', location: 'Durant, OK', link: ''}, 
    {date: '04-05-2014', venue: 'BAR Rally', location: 'Wills Point, TX', link: ''}, 
    {date: '03-07-2014', venue: 'Midnight at the Oasis', location: 'Yuma, AZ', link: ''}, 
    {date: '02-21-2014', venue: 'Gas Monkey Bar & Grill', location: 'Dallas, TX', link: ''}, 
    {date: '11-10-2013', venue: 'Southern Cross Concert', location: 'Lufkin, TX', link: ''}, 
    {date: '11-09-2013', venue: 'Private Party', location: 'Frisco, TX', link: ''}, 
    {date: '11-07-2013', venue: 'Midnight Rodeo', location: 'San Antonio, TX', link: ''}, 
    {date: '10-05-2013', venue: 'Southlake Oktoberfest', location: 'Southlake, TX', link: ''}, 
    {date: '09-26-2013', venue: 'Anthony Streeter Benefit Concert at Trees', location: 'Dallas, TX', link: ''}, 
    {date: '09-21-2013', venue: 'Private Party', location: 'New London, CT', link: ''}, 
    {date: '08-23-2013', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '08-18-2013', venue: 'Indianapolis Motor Speedway, Red Bull Indianapolis Grand Prix', location: 'Indianapolis, IN', link: ''}, 
    {date: '08-05-2013', venue: "Roxy Theatre, World's Greatest Tribute Bands on AXSTV", location: 'Los Angeles, CA', link: ''}, 
    {date: '06-22-2013', venue: 'River Rock at the Amp', location: 'Warren, OH', link: ''}, 
    {date: '06-21-2013', venue: "Rockin' on the River", location: 'Cuyahoga Falls, Ohio', link: ''}, 
    {date: '06-08-2013', venue: 'Rewind Fest, Oak Mountain Amphitheater', location: 'Birmingham, AL', link: ''}, 
    {date: '05-18-2013', venue: 'Piedras Negras Bike Fest', location: 'Piedras Negras, Mexico', link: ''}, 
    {date: '05-11-2013', venue: "Jack's Throwback 3, Gexa Energy Pavilion", location: 'Dallas, TX', link: ''}, 
    {date: '03-16-2013', venue: 'Beach Fest', location: 'Corpus Christi, TX', link: ''}, 
    {date: '03-15-2013', venue: 'Maxima Distorzion Awards, Rail Club', location: 'Fort Worth, TX', link: ''}, 
    {date: '01-04-2013', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '12-30-2012', venue: 'River Cree Resort & Casino', location: 'Enoch, Edmonton, AB, Canada', link: ''}, 
    {date: '12-28-2012', venue: 'George Knott, Wasagamack', location: 'MB, Canada', link: ''}, 
    {date: '10-13-2012', venue: 'Private Party, Hat Tricks', location: 'Lewisville, TX', link: ''}, 
    {date: '09-20-2012', venue: 'Private Party, The Ranch', location: 'Austin, TX', link: ''}, 
    {date: '09-08-2012', venue: 'Clarysville Volunteer Fire Dept Fund Raiser', location: 'Frostburg, MD', link: ''}, 
    {date: '08-31-2012', venue: "97.1 The Eagle & Russ Martin's White Trash Party, Palladium Ballroom", location: 'Dallas, TX', link: ''}, 
    {date: '08-25-2012', venue: 'Private Party', location: 'Frisco, TX', link: ''}, 
    {date: '08-18-2012', venue: "Wright for Kids's Arcadia Rock Fest", location: 'Kalamazoo, MI', link: ''}, 
    {date: '08-11-2012', venue: 'Private Party', location: 'New York, NY', link: ''}, 
    {date: '08-04-2012', venue: 'Weekends on Webster', location: 'North Tonawanda, NY', link: ''}, 
    {date: '08-03-2012', venue: 'Private Party, On the Rox at the Roxy Theatre', location: 'Hollywood, CA', link: ''}, 
    {date: '08-03-2012', venue: 'Private Party, House of Blues', location: 'Hollywood, California', link: ''}, 
    {date: '07-21-2012', venue: 'Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '07-08-2012', venue: 'Rock Around the Block', location: 'Chicago, IL', link: ''}, 
    {date: '06-30-2012', venue: "Jack's Throwback 2, Gexa Energy Pavilion", location: 'Dallas, TX', link: ''}, 
    {date: '06-23-2012', venue: 'Shooters', location: 'Texarkana, AR', link: ''}, 
    {date: '05-31-2012', venue: 'Cedar Street Courtyard', location: 'Austin, TX', link: ''}, 
    {date: '05-26-2012', venue: 'Eagle View Rally', location: 'Clovis, NM', link: ''}, 
    {date: '05-19-2012', venue: 'House of Blues', location: 'Houston, TX', link: ''}, 
    {date: '05-18-2012', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '03-10-2012', venue: 'Private Party (Wedding)', location: 'Houston, TX', link: ''}, 
    {date: '12-01-2011', venue: 'Private Party (Corporate)', location: 'Grapevine, TX', link: ''}, 
    {date: '11-18-2011', venue: 'Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '11-02-2011', venue: 'Glass Cactus', location: 'Grapevine, TX', link: ''}, 
    {date: '10-11-2011', venue: 'Private Party (Corporate)', location: 'San Pedro Sula, Honduras', link: ''}, 
    {date: '10-01-2011', venue: 'Private Party', location: 'Houston, TX', link: ''}, 
    {date: '09-09-2011', venue: 'House of Blues', location: 'Chicago, Illinois', link: ''}, 
    {date: '09-05-2011', venue: 'Speaking Rock Entertainment Complex', location: 'El Paso, TX', link: ''}, 
    {date: '09-04-2011', venue: 'Speaking Rock Entertainment Complex', location: 'El Paso, TX', link: ''}, 
    {date: '08-14-2011', venue: 'Seminole Coconut Creek Casino', location: 'Coconut Creek, FL', link: ''}, 
    {date: '08-13-2011', venue: 'Clicks Live', location: 'San Antonio, TX', link: ''}, 
    {date: '08-06-2011', venue: 'Shooters', location: 'Texarkana, AR', link: ''}, 
    {date: '07-29-2011', venue: 'POW, MIA Awareness Rally', location: 'Pocatello, ID', link: ''}, 
    {date: '07-16-2011', venue: 'Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '07-15-2011', venue: 'Bikers Adult Rally', location: 'Alvarado, TX', link: ''}, 
    {date: '07-09-2011', venue: 'Edmonton Eskimos (CFL) Halftime Show', location: 'Edmonton, AB, Canada', link: ''}, 
    {date: '07-04-2011', venue: 'Firelake Grand Casino', location: 'Shawnee, OK', link: ''}, 
    {date: '06-25-2011', venue: "Jack FM 100.3 Jack's Throwback Festival, Gexa Energy Pavilion", location: 'Dallas, TX', link: ''}, 
    {date: '06-24-2011', venue: 'House of Blues', location: 'Houston, TX', link: ''}, 
    {date: '06-04-2011', venue: 'Alleyfest', location: 'Longview, TX', link: ''}, 
    {date: '05-20-2011', venue: 'Classic Rock 104.5 FM Listener Appreciation Party, Brewster Street Icehouse', location: 'Corpus Christi, TX', link: ''}, 
    {date: '04-02-2011', venue: 'Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '03-19-2011', venue: 'Club Amnesia', location: 'Austin, TX', link: ''}, 
    {date: '03-08-2011', venue: 'Fox & Hound Fat Tuesday Party', location: 'Little Rock, AR', link: ''}, 
    {date: '02-28-2011', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '02-18-2011', venue: 'Endless Music', location: 'San Antonio, TX', link: ''}, 
    {date: '02-04-2011', venue: 'House of Blues', location: 'Houston, TX', link: ''}, 
    {date: '01-29-2011', venue: 'Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '12-18-2010', venue: "Dylan's on 9th", location: 'Port Arthur, TX', link: ''}, 
    {date: '11-05-2010', venue: 'The Gig (formerly Antones)', location: 'Beaumont, TX', link: ''}, 
    {date: '10-23-2010', venue: 'Hills and Harleys Rally', location: 'Concan, TX', link: ''}, 
    {date: '10-22-2010', venue: 'Concert Pub North', location: 'Houston, TX', link: ''}, 
    {date: '10-09-2010', venue: 'Mesa State College', location: 'Grand Junction, CO', link: ''}, 
    {date: '09-25-2010', venue: 'The Scout Bar', location: 'Houston, Texas', link: ''}, 
    {date: '09-17-2010', venue: 'NYLO Hotel, KISS Pre-party', location: 'Plano, TX', link: ''}, 
    {date: '09-10-2010', venue: 'Crosswire Club', location: 'Paris, TX', link: ''}, 
    {date: '09-02-2010', venue: 'theDrink', location: 'College Station, TX', link: ''}, 
    {date: '08-28-2010', venue: 'Private Party', location: 'Dallas, TX', link: ''}, 
    {date: '08-13-2010', venue: 'Revolution Music Room', location: 'Little Rock, AK', link: ''}, 
    {date: '08-08-2010', venue: 'Party on the Prairie', location: 'Sturgis, SD', link: ''}, 
    {date: '08-07-2010', venue: 'Party on the Prairie', location: 'Sturgis, SD', link: ''}, 
    {date: '08-06-2010', venue: 'The Lodge at Deadwood Casino', location: 'Deadwood, SD', link: ''}, 
    {date: '08-05-2010', venue: 'The Arena', location: 'Omaha, NE', link: ''}, 
    {date: '07-09-2010', venue: 'The Scout Bar', location: 'San Antonio, TX', link: ''}, 
    {date: '06-05-2010', venue: 'The Scout Bar', location: 'Houston, TX', link: ''}, 
    {date: '05-07-2010', venue: 'Cape Buffalo', location: 'Dallas, TX', link: ''}, 
    {date: '04-10-2010', venue: 'The Scout Bar', location: 'San Antonio, TX', link: ''}, 
    {date: '03-27-2010', venue: "Concerts 4 Hope, Benefit for Medical City Children's Hospital", location: 'Denton, TX', link: ''}, 
    {date: '03-04-2010', venue: 'Borderfest', location: 'Hidalgo, TX', link: ''}, 
    {date: '02-06-2010', venue: 'The Concert Pub', location: 'Houston, TX', link: ''}, 
    {date: '01-30-2010', venue: 'Scout Bar', location: 'San Antonio, TX', link: ''}, 
    {date: '01-15-2010', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '01-08-2010', venue: 'The Coda', location: 'Monroe, LA', link: ''}, 
    {date: '12-27-2009', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '12-26-2009', venue: 'House of Blues', location: 'Houston, TX', link: ''}, 
    {date: '11-14-2009', venue: 'Coconut Festival', location: 'Cape Coral, Florida', link: ''}, 
    {date: '10-31-2009', venue: 'The Roof', location: 'Houston, TX', link: ''}, 
    {date: '10-30-2009', venue: 'Concierto Stereo 100', location: 'Hermosillo, Sonora, Mexico', link: ''}, 
    {date: '10-17-2009', venue: "ZOP's Bike Rally", location: 'Abilene, TX', link: ''}, 
    {date: '10-16-2009', venue: 'The Coda', location: 'Monroe, LA', link: ''}, 
    {date: '10-03-2009', venue: 'Private Party', location: 'Dallas, TX', link: ''}, 
    {date: '09-12-2009', venue: 'The Concert Pub', location: 'Houston, TX', link: ''}, 
    {date: '08-22-2009', venue: 'Giant Side of TX Rally', location: 'Lubbock, TX', link: ''}, 
    {date: '08-20-2009', venue: 'House of Blues', location: 'Dallas, TX', link: ''}, 
    {date: '06-26-2009', venue: 'The Coda', location: 'Monroe, Louisiana', link: ''}, 
    {date: '05-08-2009', venue: 'Lakewood Bar & Grill', location: 'Dallas, Texas', link: ''}
];



function Shows() {
    const year = new Date().getFullYear()

    return(
        <div className="shows-container">
            <div className="upcoming-shows-container">
                <h2 className="logo-color1">Upcoming Shows</h2>
                <div className="description-container">
                    More dates to be announced VERY SOON! <span className="emphasis">Now booking for {year} season.</span>
                </div>
                <table className="schedule">
                    <tbody>
                        {
                            UPCOMING_SHOW_DATA.map((data, index) => (
                                <ShowEntry key={index} date={data.date} venue={data.venue} location={data.location} link={data.link} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="past-shows-container">
                <h2 className="logo-color1">Past Shows</h2>
                <table className="schedule">
                    <tbody>
                        {
                            PAST_SHOW_DATA.map((data, index) => (
                                <ShowEntry key={index} date={data.date} venue={data.venue} location={data.location} link={data.link} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Shows;